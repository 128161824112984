import React, { useEffect, useState } from 'react';
import { graphql }                    from 'gatsby';

// import styles
import { PostListNoResultElement } from '../styles/elements/Post/PostListNoResults';
import { PostsListElement }        from '../styles/elements/Post/PostsList';

// import components
import Seo         from '../components/common/Seo';
import PageMeta    from '../components/elements/PageMeta/index';
import Title       from '../components/elements/Title';
import PostPreview from '../components/elements/Post/PostPreview';
import Search      from '../components/elements/Search';

export const query = graphql`
  query($id: String!, $lang: String) {
    categories(id: { eq: $id }, lang: { eq: $lang }) {
      text
      link
      lang
      blog_backend_url
      site_title
      meta_description
    }
    
    allApiPoints {
      nodes {
        categories_text {
          no_results
        }
      }
    }
  }
`;

export default function CategoryPage( { data } ) {
  const { categories: { text, blog_backend_url, site_title, meta_description, lang: currentLanguage } } = data;

  // define the component state
  const [posts, setPosts] = useState( null );
  const [noResults, setNoResults] = useState( '' );

  /**
   * Get texts from the backend and processing changing language
   */
  useEffect( () => {
    // get loaded data from graphql
    data.allApiPoints.nodes.forEach( node => {
      const { categories_text } = node;
      let orderNumber;

      switch ( currentLanguage ) {
        case 'en':
          orderNumber = 1;
          break;

        default:
          orderNumber = 0;
      }

      setNoResults( categories_text[orderNumber].no_results );
    });
  }, [data, currentLanguage] );

  /**
   * Get categories posts
   * */
  useEffect( () => {
    async function fetchPosts() {
      const categoriesPosts     = await fetch( blog_backend_url );
      const categoriesPostsJson = await categoriesPosts.json();
      const getCategoriesPosts  = await Promise.all( categoriesPostsJson.posts.map( async result => {
        const { article_url_backend } = result;
        const posts = await fetch( article_url_backend );

        return await posts.json();
      }));

      setPosts( getCategoriesPosts );
    }

    fetchPosts();
  }, [blog_backend_url] );

  return (
    <>
      <Seo title={ site_title } description={ meta_description } lang={ currentLanguage } />
      <PageMeta type="category" text={ text } />
      <div className="container">
        <Title text={ text } additionalClass="category-title" />
        <Search className="search-page" />
        <PostsListElement>
          { posts !== null ?
            posts.length > 0 ?
              posts.map( post => {
                const {
                  image_regular, image_regular_alt, image_regular_increased, image_regular_increased_alt, title, date,
                  category, short_description, total_comments, slug,
                  global_texts: { comments_text_single, comments_text_multiple },
                  author: { name, image, image_alt }
                } = post;

                const postData = {
                  postRegularImage: image_regular,
                  postRegularImageAlt: image_regular_alt,
                  postRegularImageIncreased: image_regular_increased,
                  postRegularImageIncreasedAlt: image_regular_increased_alt,
                  postTitle: title,
                  postDate: date,
                  postCategory: category,
                  postShortDescription: short_description,
                  postCommentsCount: total_comments,
                  postCommentsTextSingle: comments_text_single,
                  postCommentsTextMultiple: comments_text_multiple,
                  postSlug: slug,
                  currentLang: currentLanguage,
                  postAuthorData: {
                    name,
                    authorImg: image,
                    image_alt
                  }
                };

                return <PostPreview data={ postData } key={ slug } />
              })
            :
              <PostListNoResultElement>
                <h3 className="no-results">{ noResults }</h3>
              </PostListNoResultElement>
          :
            <div>loading...</div>
          }
        </PostsListElement>
      </div>
    </>
  );
};